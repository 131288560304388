<template>
  <section class="w-full mt-10 lg:mt-10" v-if="this.elective.length > 0">
    <div class="max-w-6xl mx-auto">
      <div class="flex justify-between ssm:px-3 smd:pl-4 sml:pl-9">
        <h2
          class="mt-4 text-[#18479E] px-12 leading-tight text-[60px] hidden lg:block !md:text-center lg:self-center"
        >
          Online
          <span class="text-[#ec027c]"
            >{{ elective[0].program.name }} Electives
          </span>
        </h2>
        <h2 class="mt-4 text-[#18479E] text-[30px] leading-tight lg:hidden">
          Explore the Best
          <span class="text-[#ec027c]">Online Degree Programs</span> in India
        </h2>
        <div class="w-[300px] hidden lg:block">
          <img src="@/assets/home/degree/element.png" alt="element icon" />
        </div>
      </div>

      <!-- Accordion content for Masters and Bachelors -->
      <!-- desktop -->
      <div name="accordion" class="hidden lg:block">
        <div>
          <div
            v-for="(item, index) in elective"
            :key="index"
            class="accordion-item"
          >
            <div
              class="accordion-title text-[15px] lg:text-[28px] pt-5 font-bold cursor-pointer whitespace-nowrap flex justify-start items-center"
              @click="toggleAccordion(index, 'masters')"
              :class="[
                activeAccordions.masters === index
                  ? 'text-[#ec027c]'
                  : 'text-[#18479E]',
              ]"
              v-if="toggleUpdate ? index : index < 3"
            >
              <div
                v-if="activeAccordions.masters !== index"
                class="w-full flex flex-col px-12 border-b h-[150px]"
              >
                <div class="flex justify-between items-center h-full">
                  <span>{{ item.name }}</span>
                  <img
                    v-if="activeAccordions.masters !== index"
                    src="@/assets/home/degree/Group-1465.svg"
                    alt="icon"
                    class="mr-[12%]"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="activeAccordions.masters === index"
              class="accordion-content mt-2 relative h-72"
            >
              <img
                src="@/assets/home/degree/AdobeStock_257567994.svg"
                alt="Background Image"
                class="absolute top-0 left-0 w-full h-full z-0 object-cover"
              />
              <div class="absolute top-0 px-8 py-4 w-full z-10">
                <div class="flex items-center">
                  <div class="flex flex-col justify-start items-start gap-2">
                    <p class="text-[#0CB1EF] text-[40px] font-bold">
                      {{ item.name }}
                    </p>
                    <p
                      class="text-white text-[18px] w-[40rem] truncate"
                      v-html="item.description"
                    ></p>
                    <div class="flex gap-20 pt-3">
                      <div class="flex items-center gap-5 w-[130px]">
                        <img
                          src="@/assets/home/degree/Icon-awesome-graduation-cap.png"
                          alt=""
                          class="w-[29px] h-[18px]"
                        />
                        <p class="text-white font-bold text-20px">
                          {{ item.program.name }}
                        </p>
                      </div>
                      <div class="flex items-center gap-5 w-[130px]">
                        <img
                          src="@/assets/home/degree/Icon-feather-calendar.png"
                          alt=""
                          class="w-[18px] h-[20px]"
                        />
                        <p class="text-white font-bold text-20px">
                          {{ item.durations }} Years
                        </p>
                      </div>
                      <div class="flex items-center gap-5 w-[130px]">
                        <img
                          src="@/assets/home/degree/fees.png"
                          alt=""
                          class="w-[29px] h-[29px]"
                        />
                        <p class="text-white font-bold text-20px">
                          {{ formatNumber(item.indian_total_fee,'Indian') }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <a
                        href="https://apply.shardaonline.ac.in/"
                        target="_blank"
                      >
                        <button
                          class="absolute bg-[#EC027C] w-[160px] h-[50px] text-[16px] rounded-3xl text-white font-bold top-[175px]"
                        >
                          Apply Now
                        </button>
                      </a>
                    </div>
                  </div>

                  <a :href="`/${item.short_slug}/${item.slug}`">
                    <div class="flex flex-row items-center cursor-pointer">
                      <p class="text-white absolute right-[17rem] bottom-10">
                        Read More
                      </p>
                      <img
                        src="@/assets/home/degree/more-icon.png"
                        alt=""
                        class="absolute right-[11rem] bottom-5"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- desktop -->
      <!-- mobile -->
      <div class="lg:hidden">
        <div class="w-full mx-auto md:pl-44">
          <!-- Masters Data -->
          <div class="mt-5">
            <div
              v-for="(item, index) in elective"
              :key="index"
              class="w-full mb-5"
              :class="{ 'mt-5': index !== 0 }"
            >
              <div
                class="relative h-[320px] w-[320px] ssm:w-[290px] left-6 ssm:left-3 sml:ml-2"
                :class="[
                  'rounded-lg',
                  activeAccordions.masters === index
                    ? 'bg-white shadow-lg'
                    : 'border-2 border-gray-200',
                ]"
                @click="toggleAccordion(index, 'masters')"
              >
                <img
                  v-if="activeAccordions.masters === index"
                  src="@/assets/home/degree/AdobeStock_257567994(1).svg"
                  alt="background"
                  class="absolute w-full h-full object-cover rounded-xl"
                />
                <div
                  class="absolute px-4 py-4"
                  :class="{ 'text-black': activeAccordions.masters !== index }"
                >
                  <p
                    :class="[
                      activeAccordions.masters === index
                        ? 'text-[#0CB1EF]'
                        : 'text-[#18479E]',
                      'text-[28px] font-bold text-left',
                    ]"
                  >
                    {{ item.name }}
                  </p>
                  <p
                    :class="[
                      activeAccordions.masters === index
                        ? 'text-white'
                        : 'text-black',
                      'pt-3 text-[14px] text-left ssm:pr-3 w-[15rem] truncate',
                    ]"
                    v-html="item.description"
                  ></p>
                  <div class="flex w-full pt-5">
                    <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                      <img
                        :src="
                          activeAccordions.masters === index
                            ? require('@/assets/home/degree/Icon-awesome-graduation-cap.png')
                            : require('@/assets/home/degree/Icon-awesome-graduation-cap1.png')
                        "
                        alt=""
                        class="w-[20px] h-[13px]"
                      />
                      <p
                        :class="[
                          activeAccordions.masters === index
                            ? 'text-white'
                            : 'text-black',
                          'text-[14px] ssm:text-[13px] font-bold',
                        ]"
                      >
                        {{ item.program.name }}
                      </p>
                    </div>
                    <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                      <img
                        :src="
                          activeAccordions.masters === index
                            ? require('@/assets/home/degree/Icon-feather-calendar.png')
                            : require('@/assets/home/degree/Icon-feather-calendar1.png')
                        "
                        alt=""
                        class="w-[14px] h-[15px]"
                      />
                      <p
                        :class="[
                          activeAccordions.masters === index
                            ? 'text-white'
                            : 'text-black',
                          'text-[14px] ssm:text-[13px] font-bold',
                        ]"
                      >
                        {{ item.durations }} Years
                      </p>
                    </div>
                    <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                      <img
                        :src="
                          activeAccordions.masters === index
                            ? require('@/assets/home/degree/fees.png')
                            : require('@/assets/home/degree/Group-947.png')
                        "
                        alt=""
                        class="w-[20px] h-[20px]"
                      />
                      <p
                        :class="[
                          activeAccordions.masters === index
                            ? 'text-white'
                            : 'text-black',
                          'text-[14px] ssm:text-[13px] font-bold',
                        ]"
                      >
                        {{ formatNumber(item.indian_total_fee, "Indian") }}
                      </p>
                    </div>
                  </div>
                  <div class="w-full flex pt-8 items-center gap-20 ssm:gap-16">
                    <a href="https://apply.shardaonline.ac.in/" target="_blank">
                      <button
                        class="text-[16px] font-medium w-[150px] h-[50px] rounded-3xl"
                        :class="[
                          activeAccordions.masters === index
                            ? 'bg-[#EC027C] text-white'
                            : 'bg-[#EC027C] text-white',
                        ]"
                      >
                        Apply Now
                      </button>
                    </a>
                    <a :href="`/${item.short_slug}/${item.slug}`">
                      <img
                        :src="
                          activeAccordions.masters === index
                            ? require('@/assets/home/degree/more-icon.png')
                            : require('@/assets/home/degree/Group-1465.svg')
                        "
                        alt="icon"
                        class="w-[46px] h-[46px]"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Bachelors Data (similar to Masters, apply the same changes) -->
          <div v-if="selectTab === 'bachelors'" class="mt-5">
            <div
              v-for="(item, index) in bachelorsData"
              :key="index"
              class="w-full mb-5"
              :class="{ 'mt-5': index !== 0 }"
            >
              <div
                class="relative h-[320px] w-[320px]ssm:w-[290px] left-6 ssm:left-3 sml:left-12"
                :class="[
                  'rounded-lg',
                  activeAccordions.bachelors === index
                    ? 'bg-white shadow-lg'
                    : 'border-2 border-gray-200',
                ]"
                @click="toggleAccordion(index, 'bachelors')"
              >
                <img
                  v-if="activeAccordions.bachelors === index"
                  src="@/assets/home/degree/AdobeStock_257567994(1).svg"
                  alt="background"
                  class="absolute w-full h-full object-cover"
                />
                <div
                  class="absolute px-4 py-4"
                  :class="{
                    'text-black': activeAccordions.bachelors !== index,
                  }"
                >
                  <p
                    :class="[
                      activeAccordions.bachelors === index
                        ? 'text-[#0CB1EF]'
                        : 'text-[#18479E]',
                      'text-[28px] font-bold text-left',
                    ]"
                  >
                    {{ item.title }}
                  </p>
                  <p
                    :class="[
                      activeAccordions.bachelors === index
                        ? 'text-white'
                        : 'text-black',
                      'pt-3 text-[14px] text-left',
                    ]"
                  >
                    {{ item.description }}
                  </p>
                  <div class="flex w-full pt-5">
                    <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                      <img
                        :src="
                          activeAccordions.bachelors === index
                            ? require('@/assets/home/degree/Icon-awesome-graduation-cap.png')
                            : require('@/assets/home/degree/Icon-awesome-graduation-cap1.png')
                        "
                        alt=""
                        class="w-[20px] h-[13px]"
                      />
                      <p
                        :class="[
                          activeAccordions.bachelors === index
                            ? 'text-white'
                            : 'text-black',
                          'text-[14px] ssm:text-[13px] font-bold',
                        ]"
                      >
                        MBA
                      </p>
                    </div>
                    <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                      <img
                        :src="
                          activeAccordions.bachelors === index
                            ? require('@/assets/home/degree/Icon-feather-calendar.png')
                            : require('@/assets/home/degree/Icon-feather-calendar1.png')
                        "
                        alt=""
                        class="w-[14px] h-[15px]"
                      />
                      <p
                        :class="[
                          activeAccordions.bachelors === index
                            ? 'text-white'
                            : 'text-black',
                          'text-[14px] ssm:text-[13px] font-bold',
                        ]"
                      >
                        {{ item.years }}
                      </p>
                    </div>
                    <div class="w-[100px] ssm:w-[90px] flex items-center gap-2">
                      <img
                        :src="
                          activeAccordions.bachelors === index
                            ? require('@/assets/home/degree/fees.png')
                            : require('@/assets/home/degree/Group-947.png')
                        "
                        alt=""
                        class="w-[20px] h-[20px]"
                      />
                      <p
                        :class="[
                          activeAccordions.bachelors === index
                            ? 'text-white'
                            : 'text-black',
                          'text-[14px] ssm:text-[13px] font-bold',
                        ]"
                      >
                        {{ item.fees }}
                      </p>
                    </div>
                  </div>
                  <div class="w-full flex pt-8 items-center gap-20 ssm:gap-16">
                    <button
                      class="text-[16px] font-medium w-[150px] h-[50px] rounded-3xl"
                      :class="[
                        activeAccordions.bachelors === index
                          ? 'bg-[#EC027C] text-white'
                          : 'bg-[#EC027C] text-white',
                      ]"
                    >
                      Apply Now
                    </button>
                    <img
                      :src="
                        activeAccordions.bachelors === index
                          ? require('@/assets/home/degree/more-icon.png')
                          : require('@/assets/home/degree/Group-1465.svg')
                      "
                      alt="icon"
                      class="w-[46px] h-[46px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile -->
      <p
        class="text-[#EC027C] text-[18px] font-medium text-center lg:mt-20 cursor-pointer"
        v-if="elective.length > 3"
        @click="toggleUpdate = !toggleUpdate"
      >
        {{ toggleUpdate ? "Load Less" : "Load More" }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ExploreDegree",
  props: ["elective"],
  data() {
    return {
      mastersData: [
        {
          title: "Logistics and Supply Chain Management",
          description:
            "Understand the practices, technologies, and trends that drive global supply chains.",
          years: "2 years",
          fees: "₹ 1,40,000",
        },
        {
          title: "Logistics and Supply Chain Management",
          description:
            "Understand the practices, technologies, and trends that drive global supply chains.",
          years: "2 years",
          fees: "₹ 1,40,000",
        },
        {
          title: "Logistics and Supply Chain Management",
          description:
            "Understand the practices, technologies, and trends that drive global supply chains.",
          years: "2 years",
          fees: "₹ 1,40,000",
        },
      ],

      activeAccordions: {
        masters: 0,
        bachelors: 0,
      },
      toggleUpdate: false,
    };
  },

  methods: {
    toggleAccordion(index, type) {
      if (this.activeAccordions[type] === index) {
        this.activeAccordions[type] = null;
      } else {
        this.activeAccordions[type] = index;
      }
    },
    formatNumber(num, formatType) {
    // Convert number to string if it's not already
    let numStr = num.toString();

    // Remove all periods and everything after the first period
    numStr = numStr.split('.')[0];

    // Format the integer part based on the format type
    if (formatType === 'US') {
        // Add commas for US dollar format
        numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return numStr;
    } else if (formatType === 'Indian') {
        // Add commas for Indian numbering system
        let lastThree = numStr.slice(-3);
        let otherNumbers = numStr.slice(0, -3);
        if (otherNumbers !== '') {
            lastThree = ',' + lastThree;
        }
        numStr = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
        return numStr;
    } else {
        throw new Error('Invalid format type. Use "US" or "Indian".');
    }
}
  },
};
</script>

<style scoped>
.accordion-item {
  margin-bottom: 10px;
}

.accordion-content {
  position: relative;
  overflow: hidden;
  padding: 20px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.w-full {
  width: 100%;
}

.p-5 {
  padding: 20px;
}

.m-0 {
  margin: 0;
}
</style>
