<template>
  <div class="px-5 lg:px-8">
    <div class="max-w-7xl mx-auto my-20 lg:my-60">
      <div class="flex flex-wrap-reverse lg:flex-nowrap gap-9 justify-center">
        <div class="flex flex-col gap-4 justify-center">
          <h1 class="text-[30px] lg:text-[40px] xl:text-[60px] leading-tight font-normal">
            <span class="text-[#18479E]">Sharda Online Recreation </span><br />
            <span class="text-[#EC027C]"> And Engagement</span>
          </h1>
          <p class="text-[16px] lg:text-[24px] lg:w-[463px]">
            With SHORE, students can experience a campus-like atmosphere,
            empowering them to engage in networking, participate in non-academic
            programs, and get industry exposure.
          </p>
          <a href="/shore">
          <button
            class="h-[50px] w-[160px] border border-[#EC027C] text-[#EC027C] lg:text-[16px] font-bold rounded-[25px]"
          >
            Go to SHORE
          </button>
        </a>
        </div>
        <div class="w-full lg:w-[450px] xl:w-[515px]">
          <img
            src="@/assets/home/beyondacademics/Group_mobile.png"
            alt=""
            srcset=""
            class="w-full h-[297px] lg:w-[450px]  xl:w-[515px] lg:h-[481px]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeyondAcademics",
};
</script>

<style></style>
