<template>
  <section class="w-full lg:my-18">
    <div class="max-w-6xl mx-auto p-7">
      <div class="flex flex-wrap-reverse lg:flex-nowrap justify-between">
        <h2
          class="mt-4 text-[#18479E] leading-tight text-[60px] hidden lg:block"
        >
          Why <span class="text-[#ec027c]">Sharda Online</span> would<br /> be an ideal choice for you
        </h2>
        <h2 class="mt-4 text-[#18479E] text-[30px] leading-tight lg:hidden">
          Why
          <span class="text-[#ec027c]"
            >Sharda Online<br />
            is your</span
          >
          Ideal Choice
        </h2>

        <div class="w-[300px] hidden lg:block">
          <img
            src="@/assets/home/whysharda/leaf.svg"
            alt="element icon"
            class="w-[300px] h-[200px]"
          />
        </div>
      </div>
      <div class="flex gap-5 mt-5">
        <div
          class="lg:text-[28px] border-b pb-1 cursor-pointer"
          :class="[
            selectTab == 'academicApproach'
              ? 'text-[#18479E] border-[#18479E] font-bold'
              : 'text-[#ec027c]  border-[#ec027c] font-medium ',
          ]"
          @click="selectTab = 'academicApproach'"
        >
          Academic Approach
        </div>
        <div class="border-r-2 border-gray-400 mt-2"></div>
        <div
          class="lg:text-[28px] border-b pb-1 cursor-pointer"
          :class="[
            selectTab == 'learningMethodology'
              ? ' text-[#18479E] border-[#18479E] font-bold'
              : 'text-[#ec027c]  border-[#ec027c] font-medium',
          ]"
          @click="selectTab = 'learningMethodology'"
        >
          Learning Methodology
        </div>
      </div>
      <div class="mt-5">
        <div
          v-if="selectTab == 'academicApproach'"
          class="flex flex-col gap-5 lg:gap-0"
        >
          <div
            class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg content--sticky"
          >
            <div>
              <h2
                class="text-[#0CB1EF] text-[20px] lg:text-[30px] font-medium text-center lg:text-left"
              >
                Learn Anytime Anywhere
              </h2>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Round-the-clock access to live and recorded lectures to enrich
                your knowledge.
              </p>
            </div>
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whysharda/circum@3x@2x.png"
              alt="element icon"
            />
          </div>

          <div
            class="p-10 bg-white flex-col items-center flex lg:flex-row lg:justify-between gap-2 rounded-lg border content--sticky"
          >
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whysharda/engagement@3x@2x.png"
              alt="element icon"
            />
            <div>
              <h2
                class="text-[#18479E] text-[20px] lg:text-[30px] font-medium text-center whitespace-nowrap lg:text-right"
              >
                Access to Exclusive Resources
              </h2>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Our vast repository of online scholarly videos will fulfill your
                academic requirements
              </p>
            </div>
          </div>

          <div
            class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg content--sticky"
          >
            <div>
              <h2
                class="text-[#0CB1EF] text-[20px] lg:text-[30px] font-medium text-center lg:text-left"
              >
                Industry-Aligned Programs
              </h2>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Industry-approved curriculum to enhance relevant competencies
                for a better future.
              </p>
            </div>
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whysharda/feedback@3x@2x.png"
              alt="element icon"
            />
          </div>

          <div
            class="p-10 bg-white flex-col items-center flex lg:flex-row justify-between gap-2 rounded-lg border content--sticky"
          >
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whysharda/personal@3x@2x.png"
              alt="element icon"
            />
            <div>
              <h2
                class="text-[#18479E] text-[20px] lg:text-[30px] font-medium text-center whitespace-nowrap lg:text-right"
              >
                Eminent Educators
              </h2>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Learn from experts to get the latest industry insights leading to professional enhancement.
              </p>
            </div>
          </div>

          <div
            class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg content--sticky"
          >
            <div>
              <h2
                class="text-[#0CB1EF] text-[20px] lg:text-[30px] font-medium text-center lg:text-left whitespace-nowrap"
              >
                Practical Learning
              </h2>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Case studies, simulations, and activities
              </p>
            </div>
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whysharda/tech@3x@2x.png"
              alt="element icon"
            />
          </div>

          <div
            class="p-10 bg-white flex-col items-center flex lg:flex-row justify-between gap-2 rounded-lg border content--sticky"
          >
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whysharda/E-library.webp"
              alt="element icon"
            />
            <div>
              <h2
                class="text-[#18479E] text-[20px] lg:text-[30px] font-medium text-center lg:text-right"
              >
              E-library
              </h2>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Access the same extensive library facilities as regular students,<br> including full use of our comprehensive E-library resources.
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="selectTab == 'learningMethodology'"
          class="flex flex-col gap-5 lg:gap-0"
        >
          <div
            class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg content--sticky"
          >
            <div>
              <h2
                class="text-[#0CB1EF] text-[20px] lg:text-[30px] font-medium text-center lg:text-left"
              >
                Curriculum Design and Content Delivery
              </h2>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Modularization
              </p>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Multimedia Content
              </p>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Learning Management System (LMS)
              </p>
            </div>
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whyShardaNew/Curriculum-Design-and-Content-Delivery.webp"
              alt="element icon"
            />
          </div>
          <div
            class="p-10 bg-white flex-col items-center flex lg:flex-row lg:justify-between gap-2 rounded-lg border content--sticky"
          >
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whyShardaNew/Engagement-and-Interaction.webp"
              alt="element icon"
            />
            <div>
              <h2
                class="text-[#18479E] text-[20px] lg:text-[30px] font-medium text-center whitespace-nowrap lg:text-right"
              >
                Engagement and Interaction
              </h2>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Discussion Forums
              </p>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Live Sessions
              </p>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Group Work
              </p>
            </div>
          </div>
          <div
            class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg content--sticky"
          >
            <div>
              <h2
                class="text-[#0CB1EF] text-[20px] lg:text-[30px] font-medium text-center lg:text-left"
              >
                Assessment and Feedback
              </h2>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Formative Assessments
              </p>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Summative Assessments
              </p>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Peer Review
              </p>
            </div>
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whyShardaNew/ssessment-and-Feedback.webp"
              alt="element icon"
            />
          </div>
          <div
            class="p-10 bg-white flex-col items-center flex lg:flex-row justify-between gap-2 rounded-lg border content--sticky"
          >
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whyShardaNew/Personalization-and-Support.webp"
              alt="element icon"
            />
            <div>
              <h2
                class="text-[#18479E] text-[20px] lg:text-[30px] font-medium text-center whitespace-nowrap lg:text-right"
              >
                Personalization and Support
              </h2>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Adaptive Learning Tools
              </p>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Tutoring and Mentoring
              </p>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Accessibility and Inclusivity
              </p>
            </div>
          </div>
          <div
            class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg content--sticky"
          >
            <div>
              <h2
                class="text-[#0CB1EF] text-[20px] lg:text-[30px] font-medium text-center lg:text-left whitespace-nowrap"
              >
                Technology and Infrastructure
              </h2>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Reliable Tech Tools
              </p>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Cybersecurity Measures
              </p>
              <p
                class="text-[14px] lg:text-[18px] text-white text-center lg:text-left"
              >
                Tech Support
              </p>
            </div>
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whyShardaNew/Technology-and-Infrastructure.webp"
              alt="element icon"
            />
          </div>
          <div
            class="p-10 bg-white flex-col items-center flex lg:flex-row justify-between gap-2 rounded-lg border content--sticky"
          >
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/home/whyShardaNew/Evaluation-and-Continuous-Improvement.webp"
              alt="element icon"
            />
            <div>
              <h2
                class="text-[#18479E] text-[20px] lg:text-[30px] font-medium text-center lg:text-right"
              >
                Evaluation and Continuous Improvement
              </h2>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Student Feedback
              </p>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Analytics
              </p>
              <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
                Professional Development
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import Lenis from "@studio-freight/lenis";

export default {
  data() {
    return {
      selectTab: "academicApproach",
      lenis: null,
    };
  },
  mounted() {
    this.initSmoothScrolling();
    this.initScrollAnimations();
  },
  methods: {
    initSmoothScrolling() {
      this.lenis = new Lenis({
        lerp: 0.2,
        smoothWheel: true,
      });

      this.lenis.on("scroll", () => {
        gsap.globalTimeline.time(this.lenis.scroll.current);
      });

      const scrollFn = (time) => {
        this.lenis.raf(time);
        requestAnimationFrame(scrollFn);
      };

      requestAnimationFrame(scrollFn);
    },
    initScrollAnimations() {
      const contentElements = document.querySelectorAll(".content--sticky");

      contentElements.forEach((el) => {
        gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top top",
            end: "+=100%",
            scrub: true,
          },
        });
      });
    },
  },
};
</script>

<style scoped>
.content--sticky {
  position: sticky;
  top: 20%;
  padding-top: 3rem;
  transition: 0.3s ease;
}
</style>
