<template>
    <div class="">
      <headerView />
      <div class="mx-auto max-w-6xl h-full">
    <section class="w-full max-w-7xl mx-auto px-5 pt-[120px]">
      <!-- Disclosure of Information -->
      <div class="text-3xl md:text-5xl font-medium w-max text-[#18479E]">
          Mandatory <span class="text-[#EC027C]">Disclosure</span>
        </div>

      <div class="text-left flex flex-col gap-3 py-3 lg:py-6">
        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base" >
          Compliance status of Regulations UGC (ODL and Online Programs) Regulations - Self-regulation through disclosures, declarations and reports
        </div>
        <div class="flex flex-col gap-3 bg-[#F6F8FB] overflow-x-auto">
          <table class="border-separate border-spacing-3">
            <thead>
              <tr class="w-full md:text-xl md:leading-[1.5rem] md:font-base text-white">
                <th class="bg-[#18479E] px-5 py-2.5">S.No.</th>
                <th class="bg-[#18479E] px-5 py-2.5">Details</th>
                <th class="bg-[#18479E] px-5 py-2.5">Information</th>
              </tr>
            </thead>
            <tbody class="mt-2 md:text-xl md:leading-[1.5rem] md:font-base">
              <tr class="w-full text-black bg-white">
                <td class="text-left py-2 px-5">1.</td>
                <td class="w-1/2 text-left py-2 px-5">Establishing Act and Statutes</td>
                <td class="w-1/2 text-left py-2 px-5">U.P. Private Universities Act, 2019 - <a href="https://api.shardaonline.online/media/U-P-Private-Universities-Act-2019_86047.pdf"
                  target="_blank" class="text-[#18479E]">Click Here</a><br>
Statutes of Sharda University - <a href="https://api.shardaonline.online/media/Statutes-of-Sharda-University_14848.pdf"
                  target="_blank" class="text-[#18479E]">Click Here</a><br>
Ordinances of the University - <a href="https://api.shardaonline.online/media/Ordinances-of-the-University_14910.pdf"
                  target="_blank" class="text-[#18479E]">Click Here</a></td>
                
              </tr>
              <tr class="w-full text-black bg-white">
                <td class="text-left py-2 px-5">2.</td>
                <td class="w-1/2 text-left py-2 px-5">Applicaton Submitted to UGC-DEB for offering ODL/Online Programs</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.online/media/UGC-DEB-for-offering-ODL-Online-Programmes_93593.pdf"
                  target="_blank">Click Here</a></td>
                
              </tr>                                                                                 
              <tr class="w-full text-black bg-white">
                <td class="text-left py-2 px-5">3.</td>
                <td class="w-1/2 text-left py-2 px-5">Equivalence of Online Mode Degree</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.online/media/Equivalence-Online-Mode.pdf"
                  target="_blank">Click Here</a></td>
                
              </tr>
              <tr class="w-full text-black bg-white">
                <td class="text-left py-2 px-5">4.</td>
                <td class="w-1/2 text-left py-2 px-5">UGC-DEB Public Notice</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.online/media/UGC-DEB-Public-Notice.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-black bg-white">  
                <td class="text-left py-2 px-5">5.</td>
                <td class="w-1/2 text-left py-2 px-5">UGC-DEB Approval</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.online/media/DEB_Aug_2023_Approval.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-black bg-white">
                <td class="text-left py-2 px-5">6.</td>
                <td class="w-1/2 text-left py-2 px-5">Copies of Letter of Recognition from Commission</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.online/media/DEB_Aug_2023_Approval.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-black bg-white">
                <td class="text-left py-2 px-5">7.</td>
                <td class="w-1/2 text-left py-2 px-5">Formation of Committee of CIQA for Centre for Distance and Online Education (CDOE) of Sharda University</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.online/media/SU_Reg_Notification_2024_021_Formation_of_Committee_for_CIQA_of_CDOE_dt_14-05-24_24370.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-black bg-white">
                <td class="text-left py-2 px-5">8.</td>
                <td class="w-1/2 text-left py-2 px-5">Approval Document of Compliance of Mandatory Disclosure </td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.online/media/Compliance-of-Mandatory-Disclosure_39810.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-black bg-white">
                <td class="text-left py-2 px-5">9.</td>
                <td class="w-1/2 text-left py-2 px-5">E-Samadhan</td>
                <td class="w-1/2 text-left py-2 px-5">For e-Samadhan<br>Mr. Vivek Kumar, Nodal Officer,<br> <a class="text-[#18479E]" href="mailto:registrar@sharda.ac.in"
                  target="_blank">registrar@sharda.ac.in</a>, <br>AISHE id: 0541</td>
              </tr>
              <tr class="w-full text-black bg-white">
                <td class="text-left py-2 px-5">10.</td>
                <td class="w-1/2 text-left py-2 px-5">Feedback Mechanism</td>
                <td class="w-1/2 text-left py-2 px-5">Email us at<br> <a class="text-[#18479E]" href="mailto:info@shardaonline.online"
                  target="_blank">info@shardaonline.online</a></td>
              </tr>
             
            </tbody>
        </table>
          
        </div>
      </div>
      
    </section>
  </div>
  
      <footerView />
      <stickyView />
    </div>
  </template>
  
  <script>
  import headerView from "@/components/common/header.vue";
  import footerView from "@/components/common/footer.vue";
  import stickyView from "@/components/common/sticky.vue";
  export default {
    name: "MandatoryDisclosure",
    components: {
      headerView,
      footerView,
      stickyView,
    },
  };
  </script>
  
  <style></style>
  