<template>
  <div class="px-5 lg:px-8">
    <!-- program highlight -->

    <div class="max-w-6xl mx-auto py-5 lg:py-10">
      <div class="flex flex-wrap lg:flex-nowrap">
        <h2 class="text-[30px] md:text-[45px] xl:text-[60px] w-full">
          <span class="text-[#18479E]">Program </span>
          <span class="text-[#EC027C]">Highlights</span>
        </h2>
        <div class="w-full flex flex-col gap-2 mt-4">
          <div
            v-for="(highlight, index) in makeHighlightList()"
            :key="index"
            :id="'info' + index"
            class="px-4 py-2 opacity-100"
          >
            <div class="flex gap-7 lg:items-center">
              <img
                src="@/assets/elective/Path 1302@2x.png"
                alt="star"
                class="w-[18px] h-[18px] lg:w-[33px] lg:h-[33px] mt-1 lg:mt-0"
              />
              <p class="text-[16px] lg:text-[24px] font-normal text-black">
                {{ highlight }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramHighlight",
  props: ["highlight_list"],
  data() {
    return {
      Highlights: [
        {
          id: 1,
          image: require("@/assets/elective/Path 1302@2x.png"),
          content:
            "Gain skills to effectively create, execute, and oversee operations.",
        },
        {
          id: 2,
          image: require("@/assets/elective/Path 1302@2x.png"),
          content:
            "Explore global supply chain practices and current trends in logistics.",
        },
        {
          id: 3,
          image: require("@/assets/elective/Path 1302@2x.png"),
          content:
            "Assess the influence of new technologies on logistics and the efficiency of project management.",
        },
        {
          id: 4,
          image: require("@/assets/elective/Path 1302@2x.png"),
          content:
            "Showcase solutions through practical analysis within the capstone project.",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
  },
  beforeunmount() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    makeHighlightList() {
      return this.highlight_list.split("|");
    },
    handleScroll() {
      const elements = document.querySelectorAll('[id^="info"]');
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      elements.forEach((element) => {
        if (
          element.offsetTop <= scrollPosition &&
          element.offsetTop + element.offsetHeight > scrollPosition
        ) {
          element.classList.add("opacity-100");
          element.classList.remove("opacity-30");
        } else {
          element.classList.add("opacity-30");

          element.classList.remove("opacity-100");
        }
      });
    },
  },
};
</script>

<style scoped>
.opacity-0 {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.opacity-100 {
  opacity: 1;
}
</style>
