<template>
  <div class="bg-gradient-to-b from-[#FFFFFF] to-[#EFEFEF]">
    <div class="max-w-7xl m-auto my-4">
      <!--mobile design-->
      <div class="flex justify-center items-center flex-col relative lg:hidden">
        <div class="m-auto">
          <div class="text-3xl ssm:w-[18rem] w-[20rem] m-auto text-[#EC027C]">
            <span class="text-[#18479E]">An</span> Industry Recognised Degree
            <span class="text-[#18479E]">Awaits!</span>
          </div>
          <div class="ssm:w-[18rem] m-auto w-[20rem] my-4">
            A degree from Sharda Online is recognised globally, and is
            equivalent to an on-campus program from Sharda University.
          </div>
        </div>
        <div class="flex justify-center items-center ssm:h-[24rem] h-[22rem] md:h-[34rem]">
          <div class="ssm:w-[8rem] w-[13rem] top-[40%] m-auto absolute md:w-[15rem]">
            <img src="@/assets/home/header/Path1278.svg" alt="pathPetals" />
          </div>
          <div
            class="absolute ssm:top-[38%] top-[42%] md:top-[24%] ssm:left-[28%] left-[22%] w-[12rem] md:w-[20rem] md:left-[37%]"
          >
          <div
                  class="slick_dreams"
                >
                  <img
                    src="@/assets/home/header/certificate.jpg"
                    alt="sample-certificate"
                    class="w-full h-[255px]"
                    loading="lazy"
                    decoding="async"
                  />
                  <img
                    src="@/assets/home/header/MBA-DegreeSample.webp"
                    alt="sample-certificate"
                    class="w-full h-[255px]"
                    loading="lazy"
                    decoding="async"
                  />
                </div>
            <!-- <img src="@/assets/home/header/certificate.jpg" alt="certificate" /> -->
          </div>
        </div>
      </div>
      <!--mobile design-->
      <!--desktop design-->
      <div class="relative justify-center items-center hidden lg:flex gap-x-20">
        <div class="h-[40rem]">
          <div><img src="@/assets/home/header/Path1278.svg" alt="path" /></div>
          <div
            class="absolute w-[22rem] xl:w-[26rem] top-[2%] left-[5%] xl:left-[8%] 2xl:left-[7%]"
          >
          <div class="slick_dreams flex items-center justify-center">
                  <img
                    src="@/assets/home/header/certificate.jpg"
                    alt="sample-certificate"
                    class="w-[300px] h-[570px]"
                    loading="lazy"
                    decoding="async"
                  />
                  <img
                    src="@/assets/home/header/MBA-DegreeSample.webp"
                    alt="sample-certificate"
                    class="w-[300px] h-[570px]"
                    loading="lazy"
                    decoding="async"
                  />
                </div>
          </div>
        </div>
        <div>
          <div class="text-6xl font-medium w-[30rem] text-[#EC027C]">
            <span class="text-[#18479E]">An</span> Industry Recognised Degree
            <span class="text-[#18479E]">Awaits!</span>
          </div>
          <div class="w-[20rem] font-medium my-4">
            A degree from Sharda Online is recognised globally, and is
            equivalent to an on-campus program from Sharda University.
          </div>
        </div>
      </div>
      <!--desktop design-->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "certificationVue",

  mounted() {
    this.dreamsCarousel();
  },
  methods: {
    dreamsCarousel() {
      $(".slick_dreams").slick({
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // nextArrow: $(".next-rank"),
        // prevArrow: $(".prev-rank"),
        nextArrow: false,
        prevArrow: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped></style>
